<template>
  <div class="overflow-hidden">
    <default-nav-bar />
    <default-left-bar />
    <default-view />
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',

  components: {
    DefaultNavBar: () => import('./NavBar'),
    DefaultLeftBar: () => import('./LeftBar'),
    DefaultView: () => import('./View')
  }
}
</script>
